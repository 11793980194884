import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useLanguageQuery } from 'next-export-i18n';
import { endOfDay, startOfDay } from 'date-fns';
import { useMediaQuery, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CheckRounded from '@mui/icons-material/CheckRounded';
import CloseRounded from '@mui/icons-material/CloseRounded';
import EditRounded from '@mui/icons-material/EditRounded';
import FilterAlt from '@mui/icons-material/FilterAlt';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import TableBarIcon from '@mui/icons-material/TableBar';
import ViewStreamRounded from '@mui/icons-material/ViewStreamRounded';

import { onPushEvent } from '@/services/lib/gtm';
import { useNavbarContext } from '@/contexts/navbar';
import { useTranslation } from '@/hooks/translations';
import useDebounce from '@/hooks/useDebounce';
import { IRestaurantTables, ISelectedTable } from '@/views/OrdersTableView/types';
import { QSRCardModeEnum } from '@/views/QsrOrders/index';

import { CustomButton } from '@/components/common';
import QsrExportModal from '@/components/QsrExportModal';
import { drawerStyles, rightDrawerStyles } from '@/components/TableViewFilter';

import { type ITimeRangeForm, TimeRangeTypes } from '@/components/TimeRange/Form/types';
import TimeDrawer from '@/components/TimeRange/Drawer';
import TimeModal from '@/components/TimeRange/Modal';
import { IQsrOrder, OrderStatusEnum } from '@/views/QsrOrders/types';
import useLocalStorage from '@/hooks/useLocalStorage';
import PrintSettingsButton from '@/components/PrintSettingsButton';
import RestaurantService from '@/services/restaurant';
import { useUserContext } from '@/contexts/user';
import { enqueueSnackbar } from 'notistack';
import QRGroupDialog from '@/components/BrandVerificationBanner/QRModal';
import { IQrRow } from '@/views/QRCode/types';
import { uniq } from 'lodash';

export const getIcon = (option: { value: string }, selected: boolean) => {
    const commonStyles = {
        sx: {
            color: `${selected ? '#7D00D4' : '#616475'} !important`,
            fontSize: '20px',
        },
    };

    switch (option.value) {
        case 'table':
            return <ViewStreamRounded {...commonStyles} />;
        case 'card':
            return <GridViewOutlinedIcon {...commonStyles} />;
        default:
            return <FilterAlt {...commonStyles} />;
    }
};

export const stringifyQuery = (query: Record<string, any>) =>
    Object.entries(query)
        .filter(([, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

export const useQueryParams = () => {
    const { isReady, push, query } = useRouter();
    const stringQueryParams = stringifyQuery(query);

    const pushToQuery = (pushObj: Record<string, any>) => {
        push({ search: stringifyQuery({ ...query, ...pushObj }) }, undefined, { shallow: true });
    };

    return { isReady, pushToQuery, query, stringQueryParams };
};

export const useFilters = (tab: QSRCardModeEnum, pendingStatus: string[]) => {
    const restaurantService = RestaurantService.getInstance();
    const theme = useTheme();
    const { user } = useUserContext();
    const { t } = useTranslation('common');
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const isMobile = useMediaQuery(theme.breakpoints.down(650));
    const [qrList, setQrList] = useState<IRestaurantTables[]>([]);
    const [qrGroupList, setQrGroupList] = useState<IQrRow[]>([]);
    const [selectedTable, setSelectedTable] = useState<ISelectedTable>({
        ids: [],
        qrs: [],
        qrGroups: [],
    });
    const [loadingQrList, setLoadingQrList] = useState<boolean>(false);
    const [loadingQrGroupList, setLoadingQrGroupList] = useState<boolean>(false);
    const [status, setStatus] = useState<string[]>([]);
    const [tempRefId, setTempRefId] = useState<string>('');
    const [refId, setRefId] = useState<string>('');
    const [startDate, setStartDate] = useState<undefined | string>('');
    const [endDate, setEndDate] = useState<undefined | string>('');
    const [prevFilter] = useLocalStorage('qsr_order_filter', {});
    const [searchTable, setSearchTable] = useState<string | null>('');
    const [searchOrder, setSearchOrder] = useState<string | null>('');
    const debouncedTableSearch = useDebounce(searchTable, 500);
    const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false);
    const [tableModalOpen, setTableModalOpen] = useState(false);
    const [openOptions, setOpenOptions] = useState(false);
    const [options, setOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [view, setView] = useState<string>('card');
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [openFilter, setOpenFilter] = useState(false);
    const { refresh } = useNavbarContext();
    const [focused, setFocused] = useState(false);
    const textFieldRef = useRef<HTMLInputElement | null>(null);
    const isFirstRender = useRef(true);
    const { isReady, query: queryParams } = useQueryParams();
    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: TimeRangeTypes.TODAY,
        from: startOfDay(new Date()).toISOString(),
        to: endOfDay(new Date()).toISOString(),
    });

    const todayDate = new Date();
    const minDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 90);

    useEffect(() => {
        if (!isReady && !queryParams) return;
        if (prevFilter) {
            const { selectedStatus, timeRange: date, view: viewList, selectedTable: tables } = prevFilter;
            if (selectedStatus) setStatus(selectedStatus);
            if (date) setTimeRange(date);
            if (viewList) setView(viewList);
            if (tables) setSelectedTable(tables);
        }
    }, [isReady, queryParams]);

    const viewMenu = useMemo(
        () => [
            {
                id: 'card',
                label: t('Card'),
                value: 'card',
            },
            {
                id: 'table',
                label: t('Table'),
                value: 'table',
            },
        ],
        [lang],
    );

    const filterMenu = useMemo(() => {
        const list = [
            {
                id: 'pending',
                label: t('Pending'),
                value: OrderStatusEnum.Pending,
            },
            {
                id: 'approved',
                label: t('Approved'),
                value: OrderStatusEnum.Approved,
            },
            {
                id: 'declined',
                label: t('Declined'),
                value: OrderStatusEnum.Declined,
            },
            {
                id: 'scheduled',
                label: t('Scheduled'),
                value: OrderStatusEnum.Scheduled,
            },
            {
                id: 'pick-up',
                label: t('Ready for Pick up'),
                value: OrderStatusEnum.PickUp,
            },
            {
                id: 'closed',
                label: t('Closed'),
                value: OrderStatusEnum.Closed,
            },
            {
                id: 'cancelled',
                label: t('Cancelled'),
                value: OrderStatusEnum.Cancelled,
            },
            {
                id: 'failed',
                label: t('Failed'),
                value: OrderStatusEnum.Failed,
            },
        ];
        return list.filter((li) => !pendingStatus.includes(li.value));
    }, [lang, pendingStatus]);

    const getAllQrs = async () => {
        setLoadingQrList(true);
        try {
            const res = await restaurantService.getQrs(user.restaurantId);
            setQrList(res.rows);
        } catch (err: any) {
            enqueueSnackbar(t(err.message), {
                variant: 'error',
            });
        } finally {
            setLoadingQrList(false);
        }
    };

    const getAllQrGroups = async () => {
        setLoadingQrGroupList(true);
        try {
            const res = await restaurantService.getQrGroups(user.restaurantId);
            setQrGroupList(res.rows);
        } catch (err: any) {
            enqueueSnackbar(t(err.message), {
                variant: 'error',
            });
        } finally {
            setLoadingQrGroupList(false);
        }
    };

    useEffect(() => {
        if (!user.restaurantId) {
            return;
        }

        getAllQrs();
        getAllQrGroups();
    }, [user]);

    const openFilterModalHandler = useCallback(() => {
        setOpenFilter(true);
    }, []);

    const handleModalOpen = useCallback(() => {
        setDateRangeModalOpen(true);
    }, []);

    const handleTableModalOpen = useCallback(() => {
        setTableModalOpen(true);
    }, []);

    const handleTableModalClose = useCallback(() => {
        setTableModalOpen(false);
    }, []);

    useEffect(() => {
        if (!openOptions) {
            setOptions([]);
        }
    }, [openOptions]);

    useEffect(() => {
        if (isFirstRender.current && !refresh) {
            isFirstRender.current = false;
            return;
        }

        setRefreshTrigger((o) => o + 1);
    }, [refresh]);

    const handleFocus = useCallback(() => {
        setFocused(true);
    }, []);

    const handleBlur = useCallback(() => {
        setFocused(false);
    }, []);

    const tableFilterSubmitHandler = useCallback(
        (selectedItems: string[], selectedQrs: string[], selectedQrGroups: string[]) => {
            setTableModalOpen(false);
            setSelectedTable({
                ids: selectedItems,
                qrs: selectedQrs,
                qrGroups: selectedQrGroups,
            });
        },
        [],
    );

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (textFieldRef.current && !textFieldRef.current.contains(event.target as Node)) {
            setFocused(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleButtonClick = useCallback(() => {
        setRefId(tempRefId);
        handleBlur();
    }, [tempRefId]);

    const formik = useFormik<ITimeRangeForm>({
        initialValues: { type: TimeRangeTypes.TODAY, from: null, to: null },
        validationSchema: Yup.object({
            type: Yup.string()
                .oneOf(Object.values(TimeRangeTypes), t('Please select one of the given options'))
                .required(t('This field is required')),
            from: Yup.string().nullable(),
            to: Yup.string().nullable(),
        }),
        onSubmit: (val, { setSubmitting }) => {
            setTimeout(() => {
                onPushEvent(`user_use_filter_date_${val.type}`);
                setTimeRange(val);
                setSubmitting(false);
                setDateRangeModalOpen(false);
            }, 200);
        },
    });

    const removeFilter = useCallback((filter: string) => {
        switch (filter) {
            case 'timeRange':
                setTimeRange({
                    type: TimeRangeTypes.TODAY,
                    from: startOfDay(new Date()).toISOString(),
                    to: endOfDay(new Date()).toISOString(),
                });
                formik.resetForm();
                break;
            case 'status':
                setStatus([]);
                break;
            case 'table':
                setSelectedTable({
                    ids: [],
                    qrs: [],
                    qrGroups: [],
                });
                break;
            default:
                break;
        }
    }, []);

    const TimeRangeComp = isMobile ? TimeDrawer : TimeModal;

    const renderFilters = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} sm={5} sx={{ display: 'flex' }}>
                    <TextField
                        ref={textFieldRef}
                        size="small"
                        label={t('Ref Id')}
                        value={tempRefId}
                        onChange={(ev) => {
                            setTempRefId(ev.target.value);
                        }}
                        sx={{
                            flex: '1 1',
                            mt: `0 !important`,
                        }}
                        onFocus={handleFocus}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {tempRefId && (
                                        <IconButton
                                            onClick={() => {
                                                setRefId('');
                                                setTempRefId('');
                                            }}
                                            sx={{ p: '6px' }}
                                        >
                                            <CloseRounded color="primary" />
                                        </IconButton>
                                    )}
                                    {(!refId || focused) && (
                                        <IconButton onClick={handleButtonClick} sx={{ p: '6px' }}>
                                            <CheckRounded color="primary" />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <CustomButton
                                onClick={openFilterModalHandler}
                                startIcon={<TuneIcon />}
                                fullWidth
                                sx={{
                                    color: 'primary.main',
                                    borderRadius: '32px',
                                    padding: '10px 24px 10px 16px;',
                                }}
                            >
                                {t('Filter')}
                            </CustomButton>
                        </Grid>
                        <Grid item xs>
                            <PrintSettingsButton loading={loading} />
                        </Grid>
                        <Grid item xs>
                            <QsrExportModal />
                        </Grid>
                    </Grid>
                </Grid>
                <SwipeableDrawer
                    PaperProps={{ sx: isMobile ? drawerStyles : rightDrawerStyles }}
                    anchor={isMobile ? 'bottom' : 'right'}
                    onOpen={() => setOpenFilter(true)}
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                    keepMounted
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '25px',
                            right: '25px',
                            color: '#9B9EAB',
                        }}
                        onClick={() => setOpenFilter(false)}
                    >
                        <CloseRounded />
                    </IconButton>

                    <Box display="flex" alignItems="center" justifyContent="center" gap={1} mb={2}>
                        <TuneIcon />
                        <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>{t('Filters')}</Typography>
                    </Box>
                    <Typography sx={{ marginBottom: '10px', fontWeight: 500 }}>{t('View as')}</Typography>
                    <Box mb={2}>
                        {viewMenu.map((option) => {
                            const selected = view === option.value;
                            return (
                                <Chip
                                    clickable
                                    onClick={() => {
                                        setView(option.value);
                                        onPushEvent(`choose_view_as_${option.value}`);
                                    }}
                                    variant="outlined"
                                    icon={getIcon(option, selected)}
                                    label={option.label}
                                    style={{
                                        borderRadius: '8px',
                                        margin: '5px',
                                        padding: '10px',
                                        color: selected ? '#7D00D4' : '#616475',
                                        background: selected ? '#F6EBFC' : '#FFFFFF',
                                        borderColor: '#EBECF2',
                                    }}
                                />
                            );
                        })}
                    </Box>
                    <Typography sx={{ marginBottom: '10px', fontWeight: 500 }}>{t('Filter by')}</Typography>
                    <Box display="flex" flexWrap="wrap" mb={2}>
                        <Chip
                            clickable
                            onClick={() => handleModalOpen()}
                            icon={<EditRounded />}
                            variant="outlined"
                            label={t('Date range')}
                            sx={{
                                borderRadius: '8px',
                                margin: '5px',
                                padding: '10px',
                                color: timeRange.type ? '#7D00D4' : '#616475',
                                background: timeRange.type ? '#F6EBFC' : '#FFFFFF',
                                borderColor: '#EBECF2',
                                '& .MuiChip-deleteIcon': {
                                    color: '#7D00D4',
                                },
                            }}
                        />
                        <Chip
                            clickable
                            onClick={() => handleTableModalOpen()}
                            icon={<TableBarIcon />}
                            variant="outlined"
                            label={t('Table')}
                            sx={{
                                borderRadius: '8px',
                                margin: '5px',
                                padding: '10px',
                                color: selectedTable.ids.length ? '#7D00D4' : '#616475',
                                background: selectedTable.ids.length ? '#F6EBFC' : '#FFFFFF',
                                borderColor: '#EBECF2',
                                '& .MuiChip-deleteIcon': {
                                    color: '#7D00D4',
                                },
                            }}
                        />
                    </Box>
                    <Typography sx={{ marginBottom: '10px', fontWeight: 500 }}>{t('Status')}</Typography>
                    <Box display="flex" flexWrap="wrap">
                        {filterMenu.map((option) => {
                            const selected = status.includes(option.value);
                            return (
                                <Chip
                                    clickable
                                    onDelete={
                                        selected ? () => setStatus(status.filter((s) => s !== option.value)) : undefined
                                    }
                                    onClick={() => {
                                        if (selected) {
                                            setStatus(status.filter((s) => s !== option.value));
                                        } else {
                                            setStatus([...status, option.value]);
                                        }
                                        onPushEvent(`user_filter_status_${option.value}`);
                                    }}
                                    variant="outlined"
                                    label={option.label}
                                    style={{
                                        borderRadius: '8px',
                                        margin: '5px',
                                        padding: '10px',
                                        color: selected ? '#7D00D4' : '#616475',
                                        background: selected ? '#F6EBFC' : '#FFFFFF',
                                        borderColor: '#EBECF2',
                                    }}
                                    sx={{
                                        '& .MuiChip-deleteIcon': {
                                            color: '#7D00D4',
                                        },
                                    }}
                                />
                            );
                        })}
                    </Box>
                </SwipeableDrawer>
                <TimeRangeComp
                    onConfirm={setTimeRange}
                    openState={[dateRangeModalOpen, setDateRangeModalOpen]}
                    minDate={minDate}
                    noButton
                    initialValue={timeRange}
                />
                <QRGroupDialog
                    qrsLoading={loadingQrList}
                    qrsGroupLoading={loadingQrGroupList}
                    open={tableModalOpen}
                    selected={selectedTable}
                    qrList={qrList}
                    qrGroupList={qrGroupList}
                    onClose={handleTableModalClose}
                    onSubmit={tableFilterSubmitHandler}
                />
            </Grid>
        );
    };

    const qrMap = useMemo(() => {
        return qrList.reduce<Record<string, IRestaurantTables>>((a, c) => {
            a[c.id] = c;
            return a;
        }, {});
    }, [qrList]);

    const qrGroupMap = useMemo(() => {
        return qrGroupList.reduce<Record<string, IQrRow>>((a, c) => {
            a[c.id] = c;
            return a;
        }, {});
    }, [qrGroupList]);

    useEffect(() => {
        if (Object.keys(qrGroupMap).length > 0 && selectedTable.qrGroups.length > 0 && selectedTable.ids.length === 0) {
            const { ids } = selectedTable;
            if (selectedTable.qrs.length) {
                ids.push(...selectedTable.qrs);
            }
            selectedTable.qrGroups.forEach((o) => {
                const qrGroup = qrGroupMap[o];
                if (!qrGroup) {
                    return;
                }
                ids.push(...qrGroup.qr_ids);
            });
            setSelectedTable({
                ...selectedTable,
                ids: uniq(ids),
            });
        }
    }, [qrGroupMap, selectedTable]);

    const tableFilterDetails = useMemo(() => {
        const spans: any[] = [];

        const qrGroupLabels = selectedTable.qrGroups
            .slice(0, 3)
            .map((o) => qrGroupMap[o]?.name)
            .join(', ');
        if (selectedTable.qrGroups.length > 0) {
            spans.push(
                <>
                    <b>{t('Qr Group by: ')}</b>
                    {qrGroupLabels}
                    {selectedTable.qrGroups.length > 3 ? t(' and more') : ''}
                </>,
            );
        }

        const qrLabels = selectedTable.qrs
            .slice(0, 3)
            .map((o) => qrMap[o]?.params.name || qrMap[o]?.params.id)
            .join(', ');
        if (selectedTable.qrs.length > 0) {
            if (spans.length > 0) {
                spans.push(<>&nbsp;</>);
            }
            spans.push(
                <>
                    <b>{t('Table by: ')}</b>
                    {qrLabels}
                    {selectedTable.qrs.length > 3 ? t(' and more') : ''}
                </>,
            );
        }

        return spans;
    }, [qrMap, qrGroupMap, selectedTable]);

    const hasPagerFilter = useMemo(() => {
        return selectedTable.ids
            .map((selectedId) => qrList.find((o) => o.id === selectedId))
            .some((item) => item?.params.id === '0');
    }, [qrList, selectedTable]);

    const isTableAvailable = useCallback(
        (order: IQsrOrder) => {
            if (selectedTable.ids.length === 0) {
                return true;
            }

            if (order.url.startsWith('-') && !hasPagerFilter) {
                return false;
            }

            return selectedTable.ids.includes(
                qrList.find((o) => `${o.params.id}/${o.params.f1}/${o.params.f2}` === order.url)?.id || '',
            );
        },
        [qrList, selectedTable, hasPagerFilter],
    );

    return {
        selectedStatus: status,
        setSelectedStatus: setStatus,
        selectedTable,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        searchTable,
        setSearchTable,
        searchOrder,
        setSearchOrder,
        debouncedTableSearch,
        isModalOpen: dateRangeModalOpen,
        setIsModalOpen: setDateRangeModalOpen,
        openOptions,
        setOpenOptions,
        options,
        setOptions,
        loading,
        setLoading,
        timeRange,
        setTimeRange,
        refId,
        setRefId,
        refreshTrigger,
        renderFilters,
        view,
        setView,
        removeFilter,
        tableFilterDetails,
        isTableAvailable,
    };
};
