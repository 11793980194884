import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from 'react-google-recaptcha';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { recaptchaSiteKey } from '@/constants/configs';
import { useTranslation } from '@/hooks/translations';
import EventBus from '@/config/event-handler';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import { SocketConnector } from '@/contexts/socket';
import { clearLocalStorage } from '@/config/axios';
import { onPushEvent } from '@/services/lib/gtm';
import AuthService from '@/services/auth';
import { setRecaptchaModal } from '@/contexts/event.const';

function RecaptchaModal() {
    const { setUser } = useUserContext();
    const { setRestaurant } = useRestaurantContext();
    const { enqueueSnackbar } = useSnackbar();
    const authService = AuthService.getInstance();
    const { t } = useTranslation('common');
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        EventBus.on(setRecaptchaModal, () => setOpen(true));
        return () => {
            EventBus.remove(setRecaptchaModal, () => setOpen(false));
        };
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const logout = () => {
        enqueueSnackbar(t('Unsuccessful on validating'), { variant: 'error' });
        handleClose();
        setUser(null);
        setRestaurant(null);
        SocketConnector.getInstance().destroy();
        clearLocalStorage();
        EventBus.dispatch('logout');
        onPushEvent('user_logged_out_by_recaptcha_verification');
    };

    const handleClick = () => {
        setLoading(true);
        recaptchaRef.current
            ?.executeAsync()
            .then((recaptcha) => {
                if (recaptcha) {
                    authService
                        .verifyRobotStatus(recaptcha)
                        .then(() => {
                            enqueueSnackbar(
                                t(
                                    'Validaion is successful. Please re-do the action that you were trying before validation process.',
                                ),
                                { variant: 'success', autoHideDuration: 10000 },
                            );
                            handleClose();
                        })
                        .catch(logout);
                } else {
                    logout();
                }
            })
            .catch(logout)
            .finally(() => setLoading(false));
    };

    return (
        <Dialog open={open} maxWidth="xs">
            <DialogTitle>{t('Verify')}</DialogTitle>
            <DialogContent sx={{ minWidth: 300 }}>
                {recaptchaSiteKey && <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} size="invisible" />}
                <Button variant="contained" fullWidth onClick={handleClick} disabled={loading}>
                    {t("I'm not a robot")}
                </Button>
            </DialogContent>
        </Dialog>
    );
}

export default RecaptchaModal;
