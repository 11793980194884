import { useEffect, useRef } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { PrimaryButton } from '@/components/common';
import { useTranslation } from '@/hooks/translations';

import TimeRangeForm from '../Form';
import { ITimeRangeForm, TimeRangeFormikProps, TimeRangeTypes } from '../Form/types';

const TimeRangeFormik = ({
    content,
    onConfirm,
    onClose,
    initialValue,
    useCustomFormik,
    ...rest
}: TimeRangeFormikProps) => {
    const { t } = useTranslation('common');

    const init = useRef(true);

    const formik =
        useCustomFormik ||
        useFormik<ITimeRangeForm>({
            initialValues: { type: rest.options?.[0] || TimeRangeTypes.TODAY, from: null, to: null },
            validationSchema: Yup.object({
                type: Yup.string()
                    .oneOf(Object.values(TimeRangeTypes), t('Please select one of the given options'))
                    .required(t('This field is required')),
                from: Yup.string().nullable(),
                to: Yup.string().nullable(),
            }),
            onSubmit: (values, { setSubmitting }) => {
                setTimeout(() => {
                    onConfirm?.(values);
                    setSubmitting(false);
                }, 200);
            },
        });

    const { handleReset, handleSubmit, isSubmitting, setValues } = formik;

    useEffect(() => {
        if (initialValue) {
            setValues(initialValue);
        }
    }, [initialValue]);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={handleSubmit} onReset={handleReset}>
                <Grid container spacing={4}>
                    <TimeRangeForm {...rest} />
                    <Grid item xs={12}>
                        {content === 'modal' ? (
                            <DialogActions>
                                <PrimaryButton
                                    id="cancel-btn"
                                    styles={{ backgroundColor: '#C4C4C4' }}
                                    color="grey"
                                    onClick={onClose}
                                    text={t('Cancel')}
                                />
                                <PrimaryButton
                                    type="submit"
                                    disabled={isSubmitting}
                                    fullWidth
                                    text={t('Confirm')}
                                    styles={{ borderRadius: '100px' }}
                                />
                            </DialogActions>
                        ) : (
                            <PrimaryButton
                                type="submit"
                                disabled={isSubmitting}
                                fullWidth
                                text={t('Confirm')}
                                styles={{ borderRadius: '100px' }}
                            />
                        )}
                    </Grid>
                </Grid>
            </form>
        </FormikProvider>
    );
};

export default TimeRangeFormik;
